input::-webkit-outer-spin-button,
:-webkit-any-link {
  color: inherit; /* This will reset the color to its inherited value */
  text-decoration: none;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
button {
  cursor: pointer;
}
button:active {
  opacity: 70%;
}
.hidden {
  display: none;
  width: "0px";
  height: "0px";
}

.input {
  border-radius: 8px;
  border: 1px solid #dedfe0;
  padding: 12px 16px 12px 16px;
  color: #202427;
  font-family: "Tajawal", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.18px;
  width: 100%;
  box-sizing: border-box;
}
.screen {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container {
  height: 100vh;
  padding: 0px 24px;
  display: flex;
  flex-direction: column;
  width: 800px;
}

.Tx10SB16 {
  font-family: "Manrope", sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #57575b;
}
.Tx14M24 {
  font-family: "Tajawal", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.01em;
}
.Tx14R24 {
  font-family: "Tajawal", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  margin: 0px;
  color: #b11616;
}
.Tx15M24 {
  font-family: "Tajawal", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.01em;
  margin: 0px;
  color: #57575b;
}
.ManTx16M24 {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.01em;
  margin: 0px;
  color: #57575b;
}
.ManTx14R24 {
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  margin: 0px;
  color: #57575b;
}
.Tx16M24 {
  font-family: "Tajawal", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #57575b;
  margin: 0px;
}
.Tx18R32 {
  font-family: "Tajawal", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.01em;
}
.ManTx18SB24 {
  font-family: "Manrope", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0.01em;
  margin: 0px;
}
.Tx18B32 {
  font-family: "Tajawal", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.01em;
}
.Tx18M32 {
  font-family: "Tajawal", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.01em;
}
.Tx16M24 {
  font-family: "Tajawal", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.01em;
}
.Tx20M32 {
  font-family: "Tajawal", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.01em;
}
.Tx22M32 {
  font-family: "Tajawal", sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.01em;
}
.Tx24M32 {
  font-family: "Tajawal", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.01em;
}
.button {
  width: 100%;
  padding: 16px 24px;
  border-radius: 8px;
  background: #e0e0e0;
  color: #75767e;
  font-family: "Tajawal", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.16px;
  border: none;
}
.button:valid {
  background: #0a1931;
  color: #fff;
}

.approve {
  background-color: #e0e0e0;
  color: #75767e;
  border: 2px solid #75767e;
}
.approve:valid {
  background-color: transparent;
  color: #1b7e6a;
  border: 2px solid #1b7e6a;
}
.approve:hover {
  background-color: #1b7e6a;
  color: white;
  border: 2px solid #1b7e6a;
}
.reject {
  background-color: #e0e0e0;
  color: #75767e;
  border: 2px solid #75767e;
}
.reject:valid {
  background-color: transparent;
  color: #b11616;
  border: 2px solid #b11616;
}
.reject:hover {
  background-color: #b11616;
  color: white;
  border: 2px solid #b11616;
}
.whatsapp {
  background: #e0e0e0;
  color: #75767e;
}

.whatsapp:valid {
  background-color: #25d366;
  color: #ffff;
}

.dynamic-width-button {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dynamic-width-button p {
  white-space: nowrap;
  margin: 0; /* Remove default margins */
}

.navbar {
  display: flex;
  justify-content: space-between;
  padding: 16px 40px;
  background-color: #fff;
  border-bottom: 1px solid #e8ecf4;
}
.navbarButton {
  display: flex;
  align-items: center;
  background-color: #fff;
  color: #75767e;
  border: none;
  padding: 0px 24px;
}

.navbarButton:hover {
  color: #0a1931;
}
.navbarButtonBorderLayer {
  border-bottom: 2px solid transparent;
  padding: 10px 0px;
}
.navbarButtonBorderLayer:hover {
  border-bottom: 2px solid#0a1931;
}

.burgerButton {
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding-inline: 0px;
  padding-block: 0px;
  background-color: transparent;
  border: none;
}

.burgerMenuButton {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fff;
  border: none;
  border-radius: 8px;
}
.burgerMenuButton:hover {
  background-color: #e1e5e9;
}
.burgerMenu {
  position: absolute;
  top: 68px;
  right: 40px;
  background-color: #fff;
  min-width: 272px;
  border-radius: 8px;
  box-shadow: 3px 1px 16px -3px rgba(0, 0, 0, 0.1);
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  background-color: rgba(0, 0, 0, 0.5);
}
.dashboard-popup-content {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: white;
  height: 100vh;
  min-width: 320px;
  width: 800px;
  padding: 24px;
}

.contacts-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 24px;
}

.contacts-table th,
.contacts-table td {
  text-align: right;
  padding: 16px;
  background: #fff;
  font-family: "Tajawal", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.01em;
  margin-bottom: 12px;
  color: #202427;
}

.contacts-table th {
  background-color: #fff;
  color: #57575b;
}
.contacts-table tr {
  border-radius: 16px;
}

.contacts-table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}
.info-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
}

.info-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  margin-left: 8px;
}

.info-cell {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.info-title,
.info-detail {
  padding: 0px 10px; /* adjust the padding */
  text-align: left; /* for RTL languages */
}
.searchBar {
  width: 923px;
  gap: 8px;
}
.searchButton {
  width: auto;
  height: auto;
  padding: 12px;
  font-family: "Tajawal", sans-serif;
  font-size: 0px;
  font-style: normal;
  font-weight: 0;
  line-height: 0px;
  letter-spacing: 0px;
}
.reservationDetailsButtonsHolder {
  display: flex;
  justify-content: space-evenly;
  gap: 32px;
}
.reservationDetailsButtonsHolderColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 32px;
}
.hollow {
  background-color: white;
  color: #202427;
  border: 1px solid #bfc9d0;
}
.hollow:valid {
  background-color: white;
  color: #202427;
  border: 1px solid #bfc9d0;
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: white;
  border: 1px solid #dedfe0;
  color: #57575b;
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;

  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 0%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

@media (max-width: 530px) {
  .container {
    width: 390px;
  }
  .Tx22M32 {
    font-family: "Tajawal", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.01em;
  }
  .Tx24M32 {
    font-family: "Tajawal", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.01em;
  }
  .dashboard-popup-content {
    max-height: 80vh;
    width: 320px;
  }
  .searchBar {
    width: 345px;
  }
  .contacts-table {
    width: auto;
    border-spacing: 0px;
  }
  .reservationDetailsButtonsHolder {
    display: block;
    gap: 40px;
  }
}
