.HomeContainer {
  padding: 0px 40px;
}
.Hero {
  display: flex;
  background-color: #0a1931;
  justify-content: space-between;
  padding: 148px 80px;
  border-radius: 24px;
}
.heroTextSection {
  width: 50%;
}
.HeroSVG {
  width: 560px;
  height: 560px;
}
.SwitchSVG {
  width: 448px;
  height: 448px;
}
.TravelSVG {
  width: 448px;
  height: 448px;
}
.ContactSVG {
  width: 575px;
  height: 575px;
}

.Tx30R48 {
  font-family: "Tajawal", sans-serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0.01em;
  color: #202427;
}
.Tx32M40 {
  font-family: "Tajawal", sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.01em;
  color: #202427;
}
.Tx32B48 {
  font-family: "Tajawal", sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0.01em;
  color: #202427;
}
.Tx34B48 {
  font-family: "Tajawal", sans-serif;
  font-size: 34px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0.01em;
  color: #fff;
}
.Tx36B48 {
  font-family: "Tajawal", sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0.01em;
  color: #202427;
}
.Herobutton {
  width: 206px;
  padding: 16px 24px;
  border-radius: 8px;
  background-color: #fff;
  color: #0a1931;
  font-family: "Tajawal", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.16px;
  border: none;
  cursor: pointer;
}
.servicesShowcase {
  display: flex;
  justify-content: space-around;
  padding: 0px 80px;
  gap: 72px;
}
.ContactShowCase {
  display: flex;
  justify-content: space-around;
  padding: 0px 80px;
  gap: 72px;
}
.contactDetails {
  width: 40%;
}

.servicecard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f3f4f5;
  border-radius: 16px;
  padding: 80px 58px;
}
.mapHolder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.map {
  width: 1200px;
  height: 280px;
  border-radius: 16px;
}
.HomeFooter {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  direction: ltr;
  padding: 32px 40px;
  background-color: #f3f4f5;
}
.gridItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .containerCentering {
    display: block;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .HomeContainer {
    padding: 16px;
  }
  .Hero {
    flex-direction: column;
    padding: 88px 16px;
    gap: 56px;
    justify-content: center;
  }
  .heroTextSection {
    width: 100%;
  }
  .HeroSVG {
    width: 100%;
    height: 100%;
  }
  .SwitchSVG {
    width: 245px;
    height: 218px;
  }
  .TravelSVG {
    width: 245px;
    height: 218px;
  }
  .ContactSVG {
    width: 361px;
    height: 361px;
  }
  .servicesShowcase {
    flex-direction: column;
    padding: 0px 0px;
    gap: 24px;
  }
  .ContactShowCase {
    display: flex;
    flex-direction: column;
    padding: 0px 16px;
    gap: 56px;
  }
  .contactDetails {
    width: 100%;
  }
  .HomeFooter {
    grid-template-columns: 1fr 1fr;

    padding: 16px;
  }

  .map {
    width: 361px;
    height: 200px;
  }
}
